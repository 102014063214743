<template>
	<div>
		<div class="relative">
			<div class="h-64 bg-cover" :style="[`background-image: url('${props.storefront.bg_image}')`]"></div>
			<div class="absolute left-4 -bottom-[2rem] aspect-square w-32 h-32 rounded-2xl p-2 bg-white shadow-md">
				<img :src="props.storefront.pfp_image" class="w-full rounded-xl" />
			</div>
		</div>

		<div class="mx-4 mt-12">
			<h1 class="text-2xl md:text-4xl font-semibold">{{ props.storefront.name }}</h1>
			<h2 class="text-base md:text-xl text-gray-500 font-medium tracking-tight">
				{{ props.storefront.group_name }}
			</h2>

			<div class="flex flex-wrap my-4 gap-2">
				<template v-for="(link, index) in linksArray" :key="`link-${index}`">
					<a
						target="_blank"
						:href="link.url"
						class="text-sm md:text-base text-sky-800 fah:text-white bg-transparent fah:bg-sky-800 border border-sky-800/50 fah:border-sky-800 flex items-center gap-2 rounded-md px-2 py-2 transition-colors"
					>
						<fa :icon="iconMap[link.icon]" class="text-lg" />
						<span>{{ link.label }}</span>
					</a>
				</template>
			</div>

			<div
				v-if="!props.loading"
				class="space-y-2 [&_a]:text-sky-600 [&_a]:underline [&_a:hover]:no-underline mt-4"
				v-html="props.storefront.description_html"
			></div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { PropType, computed, onMounted, ref } from 'vue'
import { Storefront } from '@/types/Storefront'
import { faXTwitter, faDiscord } from '@awesome.me/kit-428607b2fd/icons/classic/brands'
import { faPalette, faGlobe } from '@awesome.me/kit-428607b2fd/icons/classic/light'

const iconMap = {
	discord: faDiscord,
	x: faXTwitter,
	palette: faPalette,
	globe: faGlobe,
}

const props = defineProps({
	storefront: {
		required: true,
		type: Object as PropType<Storefront>,
	},
	loading: {
		required: false,
		type: Boolean,
		default: false,
	},
})

const linksArray = computed(() => {
	return Array.isArray(props.storefront.links) ? props.storefront.links : []
})

const iconsLoaded = ref(false)

onMounted(() => {
	iconsLoaded.value = true
})
</script>
