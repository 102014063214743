<template>
	<div class="max-w-7xl mx-auto px-6 pb-64">
		<div class="mt-6">
			<h1 class="text-left text-2xl/10 md:text-4xl/10 font-bold mb-4 flex gap-4 w-full">
				<div class="flex-none">Live Drops</div>

				<div class="flex-grow md:hidden"></div>

				<div class="flex-none">
					<router-link
						custom
						v-slot="{ navigate }"
						:to="{
							name: 'hometeamheroes',
						}"
					>
						<button
							class="inline-block font-light text-gray-800 text-lg/10 md:text-2xl/10 align-middle hover:text-sky-600"
							@click="navigate"
						>
							All Drops
							<span class="text-base/10 md:text-lg/10"><i class="far fa-chevron-right"></i></span>
						</button>
					</router-link>
				</div>
			</h1>

			<index-carousel :slides="carouselSlides" :interval="10000" />
		</div>

		<div class="mt-16">
			<h1 class="text-left text-2xl md:text-4xl font-bold">Trending Collections</h1>
			<div
				class="mt-2 space-y-4 grid grid-cols-1 gap-6 items-stretch sm:grid-cols-2 lg:grid-cols-4 md:space-y-0 md:mx-0 md:mt-4"
			>
				<router-link
					custom
					v-slot="{ href }"
					v-for="storefront in featuredStorefronts"
					:key="`storefront-${storefront.id}`"
					:to="{
						name: 'storefront',
						params: { slug: storefront.slug },
					}"
				>
					<a
						:href="href"
						class="cursor-pointer flex flex-col rounded-xl overflow-hidden shadow-lg shadow-gray-600/50 group hover:scale-105 transition-transform"
					>
						<div class="flex-none z-10">
							<img
								:src="storefront.featured_image"
								:alt="`${storefront.name}: ${storefront.group_name}`"
							/>
						</div>
						<div class="flex flex-grow p-4 flex-col bg-white">
							<div class="font-normal text-gray-400 text-base">
								{{ storefront.group_name }}
							</div>
							<div class="font-poppins font-semibold text-xl">{{ storefront.name }}</div>
						</div>
					</a>
				</router-link>
			</div>
		</div>

		<div class="mt-16" v-if="state.rewardAssets.length > 0">
			<h1 class="text-left text-2xl/10 md:text-4xl/10 font-bold mb-4">
				Reward Store

				<router-link
					:to="{
						name: 'storefront',
						params: { slug: 'reward-room' },
					}"
					class="hidden md:inline-block font-light text-gray-800 text-2xl/10 align-middle ml-4 hover:text-sky-600"
				>
					All Rewards
					<span class="text-lg/10"><i class="far fa-chevron-right"></i></span>
				</router-link>
			</h1>
			<div class="mt-2 grid grid-cols-2 gap-6 place-items-start md:grid-cols-6 md:mx-0 md:mt-4 items-">
				<home-rewards-store-listing
					v-for="asset in state.rewardAssets"
					:asset="asset.name"
					collection="Blokpax Reward Room"
					:img="asset.image"
					:bpx_price="`${asset.floor_listing?.price ?? 0}`"
					:url="'/store/reward-room/' + asset.id"
				/>
			</div>
			<div class="mt-8 md:hidden">
				<router-link
					custom
					v-slot="{ navigate }"
					:to="{
						name: 'storefront',
						params: { slug: 'reward-room' },
					}"
				>
					<button
						class="border border-sky-700 hover:border-sky-600 py-2 px-4 text-xl font-poppins rounded-lg"
						@click="navigate"
					>
						All Rewards
						<span class="text-lg/10"><i class="far fa-chevron-right"></i></span>
					</button>
				</router-link>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useMarketStore } from '@/stores/MarketStore'
import HomeNotableSale from '@/components/HomeNotableSale.vue'
import HomeRewardsStoreListing from '@/components/HomeRewardsStoreListing.vue'
import IndexCarousel, { type Slide } from '@/components/IndexCarousel.vue'

import recentNotableSales from '@/static/recent-notable-sales.json'

const marketStore = useMarketStore()

const carouselSlides: Array<Slide> = [
	{
		title: 'Drop 2: Basketball 2024',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/basketball-24-drop-2-1600x.webp',
		cta: 'Buy and Rip Instantly!',
		link: '/hometeamheroes/basketball-2024-drop-2',
		buttonStyles: 'hover:bg-orange-500',
	},
	{
		title: 'Drop 1: Basketball 2024',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/basketball-24-drop-1-1600x.webp',
		cta: 'Buy and Rip Instantly!',
		link: '/hometeamheroes/basketball-2024-drop-1',
		buttonStyles: 'hover:bg-orange-500',
	},
	{
		title: 'Existence Preslabs',
		subtitle: 'Wonders of the First',
		image: '/image/live-rips/wonders-preslabs-1600x.webp',
		cta: 'Buy and Rip Instantly!',
		link: 'https://claim.wondersccg.com',
		buttonStyles: 'hover:bg-purple-900',
	},
	{
		title: 'Football 2024',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/football-24-drop-1-1600x.webp',
		cta: 'Buy and Rip Instantly!',
		link: '/hometeamheroes',
	},
	{
		title: 'Baseball 2024',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/baseball-24-drop-1-1600x.webp',
		cta: 'Buy and Rip Instantly!',
		link: '/hometeamheroes',
	},
	{
		title: 'Football 2023',
		subtitle: 'Home Team Heroes',
		image: '/image/live-rips/football-23.gif',
		cta: 'Buy and Rip Instantly!',
		link: '/hometeamheroes/football-2023',
	},
].map((v, i) => {
	const r = { key: i, ...v }
	return r
})

const state = reactive({
	rewardAssets: [],
})

const featuredStorefronts = computed(() => {
	return marketStore.storeFronts
		.filter((storefront) => storefront.featured_storefront_sort_order !== null)
		.sort((a, b) => a.featured_storefront_sort_order - b.featured_storefront_sort_order)
})

onMounted(async () => {
	state.rewardAssets = await marketStore.loadFeaturedListings()
})
</script>
