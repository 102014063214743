<template>
	<account-layout-with-nav>
		<div class="relative my-6 px-4 mt-4">
			<div class="md:flex mt-6">
				<aside
					v-show="state.showCollectionFilters"
					v-cloak
					class="flex-shrink-0 flex flex-col fixed w-full left-0 top-[calc(76px)] h-[calc(100%-76px)] overflow-auto md:sticky md:top-[calc(84px+1rem)] md:h-[calc(100vh-115px)] md:w-max max-sm:z-50"
				>
					<account-collection-filters
						class="inset-0 z-50 flex flex-col bg-gray-50 md:py-2 md:flex-shrink-0 flex-grow h-full"
						:loading="state.loading"
						:assetStorefronts="assetStorefronts"
						:filteredStorefronts="filteredStorefronts"
						@toggle-storefront="toggleStorefrontAndUpdateUrl"
						@done="toggleCollectionFilters(false)"
						@clear="clearFilters"
						@clear-all-filters="clearAllFilters"
						@filtersUpdated="updateUrlWithFilterQueries"
					/>
					<div class="flex-grow flex-shrink"></div>
				</aside>

				<main class="flex-grow md:ml-4">
					<div class="flex items-center space-x-2">
						<button
							class="bg-white shadow px-4 py-3 md:aspect-auto border border-gray-300 rounded-xl space-x-2 font-medium"
							@click.prevent="toggleCollectionFilters()"
						>
							<i class="fa-solid fa-bars-filter"></i>
							<span class="hidden md:inline-block">Filters</span>
							<span class="md:hidden" v-if="activeFilterCount > 0">
								{{ activeFilterCount }}
							</span>
						</button>

						<p v-if="state.loading">
							<loading-placeholder class="w-36 rounded-lg">&nbsp;</loading-placeholder>
						</p>
						<p v-else class="font-medium">
							{{ activeFilterCount === 0 ? 'Showing' : 'Found' }}

							{{
								pagedAssets.total <= pagedAssets.per_page
									? $format(pagedAssets.total)
									: `${$format(pagedAssets.from)} - ${$format(pagedAssets.to)} of ${$format(
											pagedAssets.total,
									  )}`
							}}

							total unique <pluralize :amount="pagedAssets.total" singular="card" plural="cards" />
						</p>

						<button
							v-if="accountStore.isWalletVersionOutdated"
							@click="refreshWallet"
							class="btn rounded-xl bg-yellow-300 shadow-none border-2 border-yellow-400 hover:bg-yellow-400"
						>
							Refresh
						</button>

						<div class="relative !ml-auto">
							<button
								class="shadow h-full bg-white border border-gray-300 rounded-xl p-4 font-medium leading-none"
								@click.stop="state.showSortOptions = !state.showSortOptions"
							>
								<span class="hidden md:flex items-center justify-center space-x-2">
									<p>{{ currentSort.label }}</p>
									<p>
										<i class="fa-solid fa-chevron-down"></i>
									</p>
								</span>
								<span class="md:hidden">
									<i class="fa-solid fa-arrow-up-arrow-down"></i>
								</span>
							</button>
							<div
								class="fixed flex-shrink-0 shadow-t-md border-t-2 border-gray-300 inset-0 top-auto h-auto bg-gray-50 px-4 py-4 z-50 md:absolute md:z-20 md:shadow-lg md:top-full md:bottom-auto md:right-0 md:left-auto md:bg-white md:min-w-full md:border md:border-gray-300 md:mt-2 md:rounded-xl md:px-2 md:py-2"
								v-cloak
								v-show="state.showSortOptions"
							>
								<div class="flex justify-between m-4 sm:hidden">
									<h2 class="font-semibold text-xl flex-grow text-left">Sort</h2>
									<button @click.prevent="state.showSortOptions = false">
										<i class="fa-solid fa-sharp fa-times text-xl" />
									</button>
								</div>
								<div
									v-for="sortOption in sortOptions"
									:key="`sort-${sortOption.val}`"
									class="whitespace-nowrap px-4 py-3 hover:bg-gray-100/80 rounded-lg cursor-pointer flex justify-between items-center space-x-2"
									@click="updateSort(sortOption.val)"
								>
									<p>{{ sortOption.label }}</p>
									<p v-cloak v-show="state.sortBy == sortOption.val">
										<i class="fa-regular fa-check text-xl" />
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="flex flex-wrap gap-2 mt-2">
						<div v-if="activeFilterCount > 0" class="hidden md:block">
							<filter-summary
								:filters="storeStore.state.activeFilters"
								@removeFilter="removeFilter"
								@clear-all-filters="clearAllFilters()"
								class="flex-wrap my-1 first:ml-0 last:mr-0"
							>
								<template #default v-if="state.filteredStorefrontIDs.length">
									<div
										v-for="storefront in filteredStorefronts"
										:key="`f-sf-${storefront.id}`"
										class="bg-gray-200/80 text-gray-900 rounded-lg px-4 flex justify-center items-center space-x-2"
										:class="storefront?.isMissing ? 'opacity-50' : ''"
									>
										<storefront-filter-item class="max-w-[18rem]" :storefront="storefront" />
										<button
											class="min-w-[2rem] flex-shrink-0"
											@click.prevent="toggleStorefrontAndUpdateUrl(storefront)"
										>
											<span><i class="fa fa-times" /></span>
										</button>
									</div>
								</template>
							</filter-summary>
						</div>
					</div>
					<div class="my-4 flex justify-between flex-wrap-reverse">
						<paginator
							:pages="pagedAssets.last_page"
							:current="pagedAssets.current_page"
							:show-jump-buttons="false"
							@select-page="selectPage"
							:show-pages="5"
						/>

						<div v-if="pagedAssets.last_page" class="text-right text-gray-400 text-sm self-start">
							Viewing page {{ pagedAssets.current_page }} of {{ pagedAssets.last_page }}
						</div>
					</div>
					<div class="mt-2">
						<tile-loader
							v-if="state.loading"
							:count="36"
							class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4"
						>
							<loading-tile />
						</tile-loader>
						<div v-else>
							<div v-if="pagedAssets.assets.length === 0" class="mb-8 text-center">
								<div class="w-full p-4">
									<p class="font text-2xl flex justify-center">
										<span class="text-sky-600 text-3xl mr-2">
											<i class="fa-solid fa-circle-info"></i>
										</span>
										No items found for this search
									</p>
								</div>

								<button @click="clearAllFilters" class="mt-4 btn-primary-lg mx-auto">
									Reset Filters
								</button>
							</div>
							<div v-else class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
								<storefront-asset
									v-for="asset in pagedAssets.assets"
									:key="`asset-${asset.id}`"
									:asset="asset"
									:slug="asset.storefront.slug"
									:show-storefront-name="true"
									:balance="asset.balance"
									:image-width="512"
									:show-ceiling-offer="true"
									@asset-transferred="loadUserCollection(true)"
									@accept-offer="handleOfferAccept($event, asset as Asset)"
									@asset-redeem="handleRedemption"
								/>
							</div>
							<div class="mt-4 flex justify-between flex-wrap">
								<paginator
									:pages="pagedAssets.last_page"
									:current="pagedAssets.current_page"
									:show-jump-buttons="false"
									@select-page="selectPage"
									:show-pages="5"
								/>

								<div v-if="pagedAssets.last_page" class="text-right text-gray-400 text-sm self-start">
									Viewing page {{ pagedAssets.current_page }} of {{ pagedAssets.last_page }}
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
		<section v-if="basketStore.isBasketModeEnabled" class="sticky bottom-0 w-full bg-white border-t p-4 z-10">
			<div class="max-w-screen-lg mx-auto">
				<div class="sm:flex justify-between items-center">
					<h2 class="mb-4 sm:mb-0 sm:text-2xl font-semibold">
						Selected items: {{ basketStore.basket.length }}
					</h2>
					<div v-if="basketStore.errorMsg" class="mb-4 sm:mb-0 text-red-600">
						{{ basketStore.errorMsg }}
					</div>
					<div class="flex flex-row space-x-4">
						<button @click="basketStore.toggleBasketMode(false, true)" class="grow sm:text-base">
							Cancel
						</button>
						<button
							v-if="basketStore.redeemableAssets.length > 0"
							@click="router.push({ name: 'redemptions' })"
							class="grow btn shadow-none bg-gray-100 hover:bg-gray-200 sm:text-base"
						>
							<i class="fa-light fa-truck" /> Redeem {{ basketStore.redeemableAssets.length }}
						</button>
						<button @click="router.push({ name: 'bulk-transfer' })" class="grow btn-primary sm:text-base">
							<i class="fa-solid fa-paper-plane" /> Transfer {{ basketStore.basket.length }}
						</button>
					</div>
				</div>
			</div>
		</section>

		<accept-offer-modal
			v-if="state.isAccepting && state.offer && state.modalAsset"
			:offer="state.offer"
			:asset="state.modalAsset"
			@close="handleOfferModal(false)"
			@reload="handleOfferModal(true)"
		/>

		<redemption-modal
			v-if="state.isRedeeming && state.modalAsset"
			:asset="state.modalAsset"
			@close="handleRedemptionModal(false)"
			@reload="handleRedemptionModal(true)"
		/>
	</account-layout-with-nav>
</template>
<script lang="ts">
import { Asset, Attribute } from '@/types/Asset'
import { defineComponent, onMounted, reactive, computed, onBeforeMount, ComputedRef } from 'vue'
import AccountLayoutWithNav from '@/layouts/AccountLayoutWithNav.vue'
import StorefrontAsset from '@/components/StorefrontAsset.vue'
import StorefrontFilterItem from '@/components/StorefrontFilterItem.vue'
import StorefrontFilters from '@/components/StorefrontFilters.vue'
import FilterSummary from '@/components/FilterSummary.vue'
import AccountCollectionFilters from '@/components/AccountCollectionFilters.vue'
import Paginator from '@/components/widgets/Paginator.vue'
import { useMarketStore } from '@/stores/MarketStore'
import { useWalletStore } from '@/stores/WalletStore'
import { useAccountStore } from '@/stores/AccountStore'
import TileLoader from '@/components/TileLoader.vue'
import { RouteQueryAndHash, onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import ActivityTable from '@/components/ActivityTable.vue'
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue'
import LoadingTile from '@/components/LoadingTile.vue'
import { AssetFilter, Offer, Storefront } from '@/types/Storefront'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { useAppStore } from '@/stores/AppStore'
import AcceptOfferModal from '@/components/modals/AcceptOfferModal.vue'
import RedemptionModal from '@/components/modals/RedemptionModal.vue'

import { useBulkTransferStore } from '@/stores/BulkTransferStore'

export default defineComponent({
	props: ['tab'],
	components: {
		AccountLayoutWithNav,
		AccountCollectionFilters,
		ActivityTable,
		StorefrontAsset,
		StorefrontFilterItem,
		StorefrontFilters,
		FilterSummary,
		TileLoader,
		LoadingPlaceholder,
		LoadingTile,
		Paginator,
		AcceptOfferModal,
		RedemptionModal,
	},
	setup(props) {
		const basketStore = useBulkTransferStore()
		const marketStore = useMarketStore()
		const accountStore = useAccountStore()
		const storeStore = useStorefrontStore()
		const appStore = useAppStore()
		const router = useRouter()
		const route = useRoute()
		const state = reactive({
			loading: true,
			showCollectionFilters: false,
			filteredStorefrontIDs: [] as string[],
			missingStorefronts: [] as Storefront[],
			showSortOptions: false,
			sortBy: 'name_asc',

			//pagination
			pageSize: 102,
			currentPage: 0,
			paginationState: {} as any,

			// offer accepting
			isAccepting: false,
			offer: null,
			modalAsset: null,

			// redemption
			isRedeeming: false,
		})

		const sortOptions = [
			{ label: 'Name A to Z', val: 'name_asc' },
			{ label: 'Name Z to A', val: 'name_desc' },
			{ label: 'Price Low to High', val: 'price_asc' },
			{ label: 'Price High to Low', val: 'price_desc' },
		]

		const currentSort = computed(() => {
			return sortOptions.find((o) => o.val == state.sortBy)
		})

		function updateSort(sort: string) {
			state.sortBy = sort
			state.showSortOptions = false

			let urlWithQueries
			urlWithQueries = router.resolve({
				query: {
					...router.currentRoute.value.query,
					Sort: sort,
				},
			})

			router.push(urlWithQueries.fullPath)
		}

		const activeFilterCount = computed(() => {
			return (
				Object.keys(storeStore.state.activeFilters).reduce(
					(c, key) => storeStore.state.activeFilters[key].length,
					0,
				) + (state.filteredStorefrontIDs.length ?? 0)
			)
		})

		async function loadUserCollection(
			force: boolean = false,
			options?: {
				page?: number
				collection?: string[]
				filters?: AssetFilter
			},
		) {
			return Promise.all([
				marketStore.load(force),
				accountStore.load(force, {
					collectionIds: options?.collection,
					page: options?.page,
					filters: options?.filters,
				}),
			])
		}
		const assetStorefronts: ComputedRef<Storefront[]> = computed(() => {
			if (state.loading) return []

			const storefronts = [
				...state.missingStorefronts,
				...marketStore.storeFronts.filter(function (sf) {
					for (const sfId of accountStore.storefrontIds) {
						if (sfId == sf.id) {
							return true
						}
					}

					return false
				}),
			]

			return storefronts
		})

		onBeforeMount(async () => {
			storeStore.clearFilters()
			state.loading = true

			const routeQueryEntries = Object.entries(route.query)
			if (routeQueryEntries.length > 0) {
				// (route.query) object will make values strings if there is only one value.
				// we need the value to always be an array for other components to continue working
				const activeFiltersFromUrlQuery: { [k: string]: string[] } = routeQueryEntries.reduce(
					(objDataMassaged, keyValPair) => {
						objDataMassaged[keyValPair[0]] = Array.isArray(keyValPair[1])
							? [...keyValPair[1]]
							: [keyValPair[1]]
						return objDataMassaged
					},
					{},
				)

				const { Collection: collections, Page, Sort, ...activeFilters } = activeFiltersFromUrlQuery

				if (collections) {
					collections.map((storeSlug: string) => {
						const foundStorefront = marketStore.storeFronts.find(function (sf) {
							return sf.slug === storeSlug
						})

						if (foundStorefront) {
							toggleStorefront(foundStorefront)
						}
					})
				}

				storeStore.state.activeFilters = activeFilters

				if (Sort) {
					updateSort(Sort.pop())
				}
			}

			await loadUserCollection(true, {
				page: route.query.Page ? parseInt(route.query.Page as string) : 1,
				collection: state.filteredStorefrontIDs,
				filters: {
					query: '',
					filters: storeStore.state.activeFilters,
					sort: state.sortBy,
				},
			})

			accountStore.startUserAssetMonitor()

			state.loading = false

			if (basketStore.basket.length > 0) {
				basketStore.toggleBasketMode(true)
			}
		})

		onMounted(async () => {
			state.showCollectionFilters = window.innerWidth > 768
		})

		onBeforeRouteLeave(() => {
			basketStore.toggleBasketMode(false)
			accountStore.stopUserAssetMonitor()
		})

		const filteredStorefronts = computed(() => {
			if (state.loading) return []

			return assetStorefronts.value.filter((storefront) => {
				return state.filteredStorefrontIDs.indexOf(storefront.id) >= 0
			})
		})

		const assets = computed(() => {
			if (state.loading) return []
			return accountStore.assets
		})

		const pagedAssets = computed(() => {
			const page = {
				assets: assets.value,
				...accountStore.assetPagination,
			}

			return page
		})

		const totalItems = computed(() => {
			return assets.value.reduce((total, asset) => total + asset.balance.available, 0)
		})

		function storefrontSelected(storefront: Storefront) {
			return state.filteredStorefrontIDs.indexOf(storefront.id) >= 0
		}

		function toggleStorefront(storefront: Storefront) {
			let currentIndex = state.filteredStorefrontIDs.indexOf(storefront.id)
			if (currentIndex >= 0) {
				state.filteredStorefrontIDs.splice(currentIndex, 1)
			} else {
				state.filteredStorefrontIDs.push(storefront.id)
			}

			if (state.filteredStorefrontIDs.length == 1) {
				storeStore.loadStore(state.filteredStorefrontIDs[0])
			}
		}

		function collectMissingStorefront(storeSlug: string) {
			const missingStorefront = marketStore.storeFronts.find(function (sf) {
				return sf.slug === storeSlug
			})
			if (missingStorefront) {
				missingStorefront['isMissing'] = true
				state.missingStorefronts.push(missingStorefront)
			}
		}

		function toggleStorefrontAndUpdateUrl(storefront: Storefront) {
			toggleStorefront(storefront)
			updateUrlWithFilterQueries()
			toggleCollectionFilters()
			selectPage(1)
		}

		function updateUrlWithFilterQueries() {
			const currentRouteQuery = router.currentRoute.value.query
			const storeSlugs = filteredStorefronts.value.map((store) => store.slug)
			let urlWithQueries

			if (storeSlugs.length === 1) {
				urlWithQueries = router.resolve({
					query: {
						...currentRouteQuery,
						...storeStore.state.activeFilters,
						...{ Collection: storeSlugs },
					},
				})
			} else {
				const crossStoreFilters = {}
				if (storeStore.state.activeFilters['With Offers']) {
					crossStoreFilters['With Offers'] = storeStore.state.activeFilters['With Offers']
				}
				if (storeStore.state.activeFilters['Listed']) {
					crossStoreFilters['Listed'] = storeStore.state.activeFilters['Listed']
				}

				urlWithQueries = router.resolve({
					query: {
						...crossStoreFilters,
						...{ Collection: storeSlugs },
					},
				})
			}

			router.push(urlWithQueries.fullPath)
			history.pushState(history.state, null, urlWithQueries.fullPath)
		}

		function clearAllFilters() {
			state.filteredStorefrontIDs = []
			storeStore.clearFilters()

			let urlWithQueries = router.resolve({
				query: {},
			})
			router.push(urlWithQueries.fullPath)
		}
		function clearFilters() {
			storeStore.clearFilters()
			updateUrlWithFilterQueries()
		}

		function toggleCollectionFilters(force?: boolean) {
			state.showCollectionFilters = undefined == force ? !state.showCollectionFilters : force
			appStore.mobileOnlyOverlay = state.showCollectionFilters
		}

		async function selectPage(pg: number) {
			state.loading = true
			await accountStore.load(true, {
				page: pg,
				filters: {
					query: '',
					filters: storeStore.state.activeFilters,
					sort: state.sortBy,
				},
			})
			state.loading = false

			state.currentPage = pg

			const currentRouteQuery = router.currentRoute.value.query
			let urlWithQueries

			urlWithQueries = router.resolve({
				query: {
					...currentRouteQuery,
					Page: pg,
				},
			})

			history.pushState({ ...history.state, current: urlWithQueries.fullPath }, '', urlWithQueries.href)
		}

		async function removeFilter(filter: string, val: string) {
			storeStore.removeFilter(filter, val)
			updateUrlWithFilterQueries()
		}

		async function refreshWallet() {
			accountStore.stopUserAssetMonitor()
			state.loading = true
			await loadUserCollection(true, {
				page: route.query.Page ? parseInt(route.query.Page as string) : 1,
				collection: state.filteredStorefrontIDs,
				filters: {
					query: '',
					filters: storeStore.state.activeFilters,
					sort: state.sortBy,
				},
			})
			state.loading = false
			accountStore.startUserAssetMonitor()
		}

		function handleOfferAccept(offer: Offer, asset: Asset) {
			state.offer = offer
			state.modalAsset = asset
			state.isAccepting = true
		}

		async function handleOfferModal(shouldReload: boolean) {
			state.isAccepting = false
			state.modalAsset = null
			state.offer = null

			if (shouldReload) {
				refreshWallet()
			}
		}

		function handleRedemption(asset: Asset) {
			state.modalAsset = asset
			state.isRedeeming = true
		}

		async function handleRedemptionModal(shouldReload: boolean) {
			state.isRedeeming = false
			state.modalAsset = null

			if (shouldReload) {
				refreshWallet()
			}
		}

		return {
			state,
			marketStore,
			accountStore,
			storeStore,
			assetStorefronts,
			filteredStorefronts,
			assets,
			pagedAssets,
			totalItems,
			toggleStorefront,
			toggleStorefrontAndUpdateUrl,
			route,
			storefrontSelected,
			clearFilters,
			toggleCollectionFilters,
			activeFilterCount,
			loadUserCollection,
			clearAllFilters,
			selectPage,
			sortOptions,
			currentSort,
			updateSort,
			basketStore,
			router,
			updateUrlWithFilterQueries,
			removeFilter,
			refreshWallet,
			handleOfferAccept,
			handleOfferModal,
			handleRedemption,
			handleRedemptionModal,
		}
	},
})
</script>
