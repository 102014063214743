<template>
	<div class="bpmp-storefront__filters">
		<div
			class="flex-grow flex-shrink overflow-y-auto"
			:class="isSticky ? 'md:sticky md:top-[calc(84px+1rem)] md:h-[calc(100vh-115px)] md:overflow-auto' : ''"
		>
			<div class="flex justify-between m-4 px-4 sm:hidden">
				<h2 class="font-semibold text-xl flex-grow text-center">Filters</h2>
				<button v-if="props.closable" @click.prevent="$emit('close')">
					<i class="fa-solid fa-sharp fa-times text-xl" />
				</button>
			</div>

			<div v-if="props.showCollectionFilters" class="mb-4 select-none">
				<p class="px-4"><strong>Status</strong></p>
				<filter-option
					label="All"
					@click.prevent="allStatus"
					:selected="
						(storeStore.state.activeFilters?.Listed ?? []).indexOf('Yes') < 0 &&
						(storeStore.state.activeFilters?.Auction ?? []).indexOf('Yes') < 0 &&
						(storeStore.state.activeFilters?.['Sold By Blokpax'] ?? []).indexOf('Yes') < 0
					"
				/>
				<filter-option
					label="Listed"
					@click.prevent="toggleStatus('Listed', 'Yes')"
					:selected="(storeStore.state.activeFilters?.Listed ?? []).indexOf('Yes') >= 0"
				/>
				<filter-option
					v-if="storeStore.hasFeature(Flags.FEATURE_AUCTIONS)"
					label="Auction"
					@click.prevent="toggleStatus('Auction', 'Yes')"
					:selected="(storeStore.state.activeFilters?.Auction ?? []).indexOf('Yes') >= 0"
				/>
				<filter-option
					label="Sold By Blokpax"
					@click.prevent="toggleStatus('Sold By Blokpax', 'Yes')"
					:selected="(storeStore.state.activeFilters['Sold By Blokpax'] ?? []).indexOf('Yes') >= 0"
				/>
			</div>

			<p class="px-4 flex justify-between select-none">
				<strong>Traits</strong>

				<span v-if="storeStore.numberOfActiveFilters > 0" class="text-gray-400 text-sm"
					>{{ storeStore.numberOfActiveFilters }} Selected</span
				>
			</p>
			<filter-accordian
				v-for="(options, key) in storeStore.state.metadata"
				class="select-none"
				:key="`filt-${key}`"
				:label="key"
				:options="options"
				@filter="updateFilters"
			/>
		</div>

		<div
			v-if="props.closable"
			class="flex-shrink-0 flex-grow-0 flex justify-between space-x-2 py-4 px-4 border-t border-gray-200"
		>
			<button
				v-if="storeStore.numberOfActiveFilters"
				class="shadow hover:shadow-none border border-gray-200 rounded-xl text-center w-full text-sky-600 btn-lg"
				@click.prevent="$emit('clear-all-filters')"
			>
				Clear all {{ `${storeStore.numberOfActiveFilters}` }}
			</button>
			<button class="sm:hidden shadow hover:shadow-none btn-primary-lg w-full" @click.prevent="$emit('done')">
				Close
			</button>
		</div>
	</div>
</template>
<script lang="ts">
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { defineComponent, onMounted, PropType, reactive, ref } from 'vue'
import FilterAccordian from '@/components/FilterAccordian.vue'
import FilterOption from '@/components/FilterOption.vue'
import { Flags } from '@/types/Flags'

export default defineComponent({
	components: { FilterAccordian, FilterOption },
	emits: ['done', 'clear', 'close', 'filtersUpdated', 'clear-all-filters'],
	props: {
		closable: {
			type: Boolean,
			required: false,
			default: () => true,
		},
		showCollectionFilters: {
			type: Boolean,
			required: false,
			default: () => false,
		},
	},
	setup(props, { emit: $emit }) {
		const storeStore = useStorefrontStore()
		const stickySidebarRef = ref(null)
		const isSticky = ref(false)

		const ownerOptions = [
			{
				prop: 'Any',
				val: 'Any',
			},
			{
				prop: 'Me',
				val: 'Me',
			},
		]

		function toggleStatus(filter: string, value: string) {
			storeStore.toggleFilter(filter, value)
			$emit('filtersUpdated')
		}

		function allStatus() {
			if ((storeStore.state.activeFilters?.Listed ?? []).indexOf('Yes') >= 0) {
				storeStore.toggleFilter('Listed', 'Yes', false)
			}

			if ((storeStore.state.activeFilters?.Auction ?? []).indexOf('Yes') >= 0) {
				storeStore.toggleFilter('Auction', 'Yes', false)
			}

			if ((storeStore.state.activeFilters?.['Sold By Blokpax'] ?? []).indexOf('Yes') >= 0) {
				storeStore.toggleFilter('Sold By Blokpax', 'Yes', false)
			}

			$emit('filtersUpdated')
		}

		function updateFilters(filter: string, selected: string[]) {
			// storeStore.filters[ filter ] = selected;
			storeStore.setFilters(filter, selected)
			$emit('filtersUpdated') //, state.filters)
		}

		return {
			Flags,
			props,
			storeStore,
			ownerOptions,
			updateFilters,
			stickySidebarRef,
			isSticky,
			toggleStatus,
			allStatus,
		}
	},
})
</script>
